var ContentTypeEnum = {
    json: 1,
    form: 2
}

export async function PostJson(uri, data) {
    return PostJsonWithToken(uri, data, null, ContentTypeEnum.json);
}

export async function GetJson(uri, data) {
    return GetJsonWithToken(uri, data, null, ContentTypeEnum.json);
}

export async function GetFileWithToken(uri, token, filename) {
    FetchBlob(uri, 'GET', token, filename);
}

export async function PostJsonWithToken(uri, data, token) {
    return FetchData(uri, 'POST', data, token, ContentTypeEnum.json);
}

export async function PostFormWithToken(uri, data, token) {
    return FetchData(uri, 'POST', data, token, ContentTypeEnum.form);
}

export async function GetJsonWithToken(uri, data, token) {
    return FetchData(uri, 'GET', data, token, ContentTypeEnum.json);
}

function getContentTypeString(contentType) {
    switch (contentType) {
        case ContentTypeEnum.json:
            return 'application/json';
        case ContentTypeEnum.form:
            return 'application/x-www-form-urlencoded'
    }
}

async function FetchData(uri, method, data, token, contentType) {
    console.log('uri: ' + uri);

    if (contentType === ContentTypeEnum.json)
        console.log(JSON.stringify(data));
    else {
        for (var key of data.entries()) {
            console.log(key[0] + ', ' + key[1]);
        }
    }
    

    var headers = getHeader(contentType);

    if (token) {
        headers.Authorization = 'Bearer ' + token
    }

    var options = {
        method: method,
    }

    // if (contentType === ContentTypeEnum.json) {
    //     options.headers = headers
    // }

    options.headers = headers

    if (data) {
        options.body = contentType === ContentTypeEnum.json ? JSON.stringify(data) : data
    }

    var response = await fetch(uri, options);

    response = await handleErrors(response);

    var returnData = await response.json();

    return returnData;
}

function getHeader(contentType) {
    var contentTypeString = getContentTypeString(contentType);

    if (contentType === ContentTypeEnum.json) {
        return {
            'Content-Type': contentTypeString
        }
    }
    
    return {};
}

async function FetchBlob(uri, method, token, filename) {

    var headers = {}

    var options = {
        method: method,
    }

    if (token) {
        headers.Authorization = 'Bearer ' + token
        options.headers = headers;
    }

    var response = await fetch(uri, options);

    await handleErrors(response);

    var blob = await response.blob();
        
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();    
    a.remove();  //afterwards we remove the element again    
}

async function handleErrors(response) {
    if (!response.ok)
    {
        var error = await response.json();

        throw new Error(error.title + " Details: " + JSON.stringify(error.errors));
    }
    
    return response;
}