import React from "react";
import { FormGroup, Input } from "reactstrap";

export default function CheckBoxCell({value, row: {index}, column: {id}, updateMyData}) {
    const onChange = (e) => {
        updateMyData(index, id, !value);
    }

    return  (<FormGroup check>
                <Input type="checkbox" checked={value} onChange={onChange} />
            </FormGroup>)
}