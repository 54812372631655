import React, { useState } from "react";
import { BASEAPI } from "../../constants";
import { GetJson } from "../../helpers/fetch_helpers";
import { useEffect } from "react";
import { Button, Card, CardBody, CardTitle, Col, Container, FormText, Row } from "reactstrap";
import Select from "react-select";
import { useHistory } from "react-router";

export default function BoxSelection({year, businessUnit}) {
    var history = useHistory();
    let [boxCodes, setBoxCodes] = useState();
    let [boxCode, setBoxCode] = useState();
    let [inputValue, setInputValue] = useState();

    const buildBoxOptions = () => {
        var options = boxCodes && boxCodes.length > 0 ? boxCodes.map((boxCode, index) => {
            return { label: boxCode.boxName + " [" + boxCode.boxCode + "]", value: boxCode.boxCode}
        }) : [];

        return options;
    }

    const onBoxChange = (e) => {
        if (e) { //ignore if it is being cleared
        var val = e.value;
        setBoxCode(val);
        } else {
            setBoxCode(null);
        }
    }

    const getBoxList = async () => {
        var uri = BASEAPI + "/api/boxContents/GetBoxList/" + year + "/" + businessUnit
        var result = await GetJson(uri)
        .catch(function(error){
            console.log(error);
        })

        setBoxCodes(result);
    }

    const transferBox = () => {
        if (boxCode !== null) {
            history.push("/boxContents/" + year + "/" + businessUnit + "/" + boxCode);
        }
    }

    useEffect(() => {
        getBoxList();
    }, [])

    return (
        <Card color="danger" outline>
            <CardBody>
                <CardTitle tag="h5">Select or Enter the Box Name or Number</CardTitle>
                <Container>
                    <Row>
                        <Col xs="10">
                            <Select className="hamperSearch" options={buildBoxOptions()} name="boxCodes" placeholder="Choose a Box" isSearchable={true} isClearable={true} onChange={onBoxChange} />
                            <FormText>Filter results by typing in the selection box and then selecting item, to enable the Go button</FormText>
                        </Col>
                        <Col xs="2">
                            <Button color="primary" disabled={!boxCode} onClick={transferBox}>Go</Button>
                        </Col>
                    </Row>
                </Container>
            </CardBody>
        </Card>
    )
}