import React from 'react';
import { Redirect, useHistory, useLocation } from 'react-router';
import BoxContents from './components/BoxContents/BoxContents';
import HamperContents from './components/BoxContents/HamperContents';

import './custom.css'

export default function App() {
  const history = useHistory();
  const location = useLocation();
  const isBoxContents = location.pathname.toLowerCase().startsWith("/boxcontents");
  const isHamperContents = location.pathname.toLowerCase().startsWith("/hampercontents");
  const isAu = location.pathname.toLowerCase().startsWith("/au");
  const isNz = location.pathname.toLowerCase().startsWith("/nz");
  const year = new Date().getFullYear();
  const uriBase = "/hampercontents/" + year;
  var uri = uriBase;

  if (isBoxContents) {
    return <BoxContents />
  } else if (isHamperContents) {
    return <HamperContents />
  } else if (isAu) {
    var uri = uriBase + "/au/";
  } else if (isNz) {
    var uri = uriBase + "/nz/"
  } else {
    var uri = uriBase + "/au/";  //default is redirect as if /au
  }

  //location redirect to HamperContents with current year and bu from location
  return <Redirect to={uri} />
}
