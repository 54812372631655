import React, { useState } from "react";
import { BASEAPI } from "../../constants";
import { GetJson } from "../../helpers/fetch_helpers";
import { useEffect } from "react";
import { Button, Card, CardBody, CardTitle, Col, Container, FormText, Row } from "reactstrap";
import Select from "react-select";
import { useHistory } from "react-router";

export default function HamperSelection({year, businessUnit}) {
    var history = useHistory();
    let [hamperCodes, setHamperCodes] = useState();
    let [hamperCode, setHamperCode] = useState();

    const buildHamperOptions = () => {
        var options = hamperCodes && hamperCodes.length > 0 ? hamperCodes.map((hamperCode, index) => {
            return { label: hamperCode.hamperName + " [" + hamperCode.hamperCode + "]", value: hamperCode.hamperCode}
        }) : [];

        return options;
    }

    const onHamperChange = (e) => {
        if (e) { //ignore if it is being cleared
        var val = e.value;
        setHamperCode(val);
        } else {
            setHamperCode(null);
        }
    }

    const getHamperList = async () => {
        var uri = BASEAPI + "/api/boxContents/GetHamperList/" + year + "/" + businessUnit
        var result = await GetJson(uri)
        .catch(function(error){
            console.log(error);
        })

        setHamperCodes(result);
    }

    const transferHamper = () => {
        if (hamperCode !== null) {
            history.push("/hamperContents/" + year + "/" + businessUnit + "/" + hamperCode);
        }
    }

    useEffect(() => {
        getHamperList();
    }, [])

    return (
        <Card color="danger" outline>
            <CardBody>
                <CardTitle tag="h5">Select or Enter the Hamper Name</CardTitle>
                <Container>
                    <Row>
                        <Col xs="10">
                            <Select className="hamperSearch" options={buildHamperOptions()} name="hamperCodes" placeholder="Choose a Hamper" isSearchable={true} isClearable={true} onChange={onHamperChange} />
                            <FormText>Filter results by typing in the selection box and then selecting item, to enable the Go button</FormText>
                        </Col>
                        <Col xs="2">
                            <Button color="primary" disabled={!hamperCode} onClick={transferHamper}>Go</Button>
                        </Col>
                    </Row>
                </Container>
            </CardBody>
        </Card>
    )
}