import React, { useEffect, useMemo, useState } from "react"
import { FaBoxOpen } from "react-icons/fa";
import { Col, Container, Row } from "reactstrap";
import CheckBoxCell from "../Utility/ReactTableEditors/CheckBoxCell"
import RTable from "../Utility/RTable"

export default function BoxTable({boxContents, inHamper}) {
    var [skuItems, setSkuItems] = useState();

    const columns = useMemo(() => [
        {
             Header: 'In Box',            
             accessor: 'inBox',
             Cell: CheckBoxCell
        },
        {
            Header: 'Item',
            accessor: 'itemDescription'
        },
        {
            Header: 'Size',
            accessor: 'catalogueSize'
        },
        {
            Header: 'Quantity',
            accessor: 'catalogueQty'
        }
    ], [])

    const getRowStyle = (row) => {
        return row.values.inBox ? {style: {"background-color": 'rgb(217, 255, 209)'}} : {}
    }

    const updateMyData = (rowIndex, columnId, value) => {
        setSkuItems(old =>
            old.map((row, index) => {
              if (index === rowIndex) {
                return {
                  ...old[rowIndex],
                  [columnId]: value,
                }
              }
              return row
            })
          )
      }

      useEffect(() => {
        boxContents.skuItems.forEach(skuItem => {
            skuItem.inBox = false;
        });
        setSkuItems(boxContents.skuItems);
      }, [boxContents])

    return (
        <>
        {skuItems &&
        <div>    
            <div className="boxTitle">{inHamper ? 
                <Container className="boxTitleContainer">
                    <Row className="flex-nowrap">
                        <Col xs="1"><FaBoxOpen /></Col>
                        <Col xs="6" md="auto">{boxContents.boxName} [{boxContents.boxCode}]</Col>
                    </Row>
                    <Row>
                        <Col xs="1" className="boxLabel">Box</Col>
                    </Row>
                </Container> :
                <>{boxContents.boxName} [{boxContents.boxCode}]</>
            }
            </div>

            <RTable columns={columns} data={skuItems} updateMyData={updateMyData} getRowStyle={getRowStyle} />

            <div className="text-right p-2 font-italic">
                Found {skuItems.filter(x => x.inBox === true).length} of {skuItems.length} items in Box
            </div>
        </div>
        }
        </>
    )
}