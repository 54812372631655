import React from "react";
import { useTable } from "react-table";
import { Table } from "reactstrap";

export default function RTable({columns, data, updateMyData, getRowStyle}) {
    const tableInstance = useTable({columns, data, updateMyData});

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;


    return (
        <>
            <Table {...getTableProps} hover bordered responsive>
                <thead>
                    {
                        headerGroups && headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup && headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps()}>
                                            {column && column.render('Header')}
                                        </th>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </thead>
                <tbody {...getTableBodyProps()}>
                    {
                        rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps(getRowStyle(row))}>
                                    {
                                        row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {
                                                        cell.render('Cell')
                                                    }
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </>
    )
}