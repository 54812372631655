/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Spinner } from "reactstrap";
import { BASEAPI } from "../../constants";
import { GetJson } from "../../helpers/fetch_helpers";
import useDocumentTitle from "../Utility/useDocumentTitle";
import "./BoxContent.css";
import BoxContentsHeader from "./BoxContentsHeader";
import BoxTable from "./BoxTable";

export default function BoxContents() {
    const [document_title, setDocumentTitle] = useDocumentTitle("Chrisco - Box Contents");

    var location = useLocation();
    var year = null;
    var businessUnit = null;
    var boxCode = null;
    var [boxContents, setBoxContents] = useState();
    var [isLoading, setIsLoading] = useState(false);    

    var pathComponents = location.pathname.split('/');

    if (pathComponents.length === 5) {
        year = pathComponents[2];
        businessUnit = pathComponents[3];
        boxCode = pathComponents[4];
    }

    async function getContents() {
        setIsLoading(true);

        var uri = BASEAPI + "/api/boxContents/GetBoxContent/" + year + "/" + businessUnit + "/" + boxCode
        var result = await GetJson(uri, null)
        .catch(function(error){
            console.log(error);
            //TODO: show error to user via toast or like
            //dispatchServerError({type: "SET_ERROR",errorTitle:"Error getting reviews for term", errorMessage: error.message});
        })
        .finally(() => setIsLoading(false));

        if (result)
        {
            // result.skuItems.forEach(skuItem => {
            //     skuItem.inBox = false;
            // });
            setBoxContents(result);
            //setSkuItems(result.skuItems);
        }
    }

    useEffect(() => {
        getContents()
    },[year, businessUnit, boxCode])

    return (
        <div>
            <BoxContentsHeader businessUnit={businessUnit} />

            {isLoading &&
                <Spinner color="secondary" />
            }

            {boxContents &&
            <div>
                <BoxTable boxContents={boxContents} inHamper={false} />
            </div>
            }
        </div>
    )
}