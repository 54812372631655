import React, { useState } from "react";
import { FaAngleDoubleDown, FaAngleUp, FaFacebook, FaInstagramSquare } from "react-icons/fa";
import { Button, Col, Collapse, Container, Row } from "reactstrap";
import logo from '../../images/DacLogoInverted.png';
import topBanner from '../../images/2023-Banner-LandingPage-BOXCONTENTS-TOP.jpg';
import "./BoxContent.css";
import HamperSelection from "../Selectors/HamperSelection";
import BoxSelection from "../Selectors/BoxSelection";

export default function HamperContentsHeader({year, businessUnit}) {    
    const isAu = businessUnit.toLowerCase() === "au";

    let [selectHamper, setSelectHamper] = useState(true);

    const toggleHamperSelect = () => setSelectHamper(!selectHamper);

    return (
        <Container fluid>
            <Row className="contentsBanner">
                <Col className="float-start contentsBannerTitle">Hamper Contents List</Col>
                <Col> <div className="float-right"><img src={logo} alt="Chrisco Hampers" className="contentsBannerLogo" /> </div></Col>
            </Row>
            <Row className="contentsBanner">
                <Col> <div className="float-left"><img src={topBanner} alt="Chrisco Hampers" className="contentsBannerImage" /> </div></Col>            
            </Row>
            <Row>
                <Col>
                    <Button size="sm" color="secondary" className="anotherHamperBtn" onClick={toggleHamperSelect} >Select or Enter the Hamper Name{selectHamper ? <FaAngleUp /> : <FaAngleDoubleDown />}</Button>
                </Col>
                <Col><div className="float-right contentHeaderSubtitle">{isAu ? 'www.chrisco.com.au' : 'www.chrisco.co.nz'} | {isAu ? '1800 830 830' : '0800 555 333'}</div></Col>
            </Row>
            <Row>
                <Col> 
                </Col>
                <Col><div className="float-right contentHeaderSubtitle"><FaInstagramSquare />{isAu ? '@chrisco.au' : '@chrisco.nz'} <FaFacebook />{isAu ? '/chrisco.au' : '/chrisco.newzealand'}</div></Col>
            </Row>
            {selectHamper &&
            <Row>
                <Col sm="6">
                    <Collapse isOpen={selectHamper}>
                        <HamperSelection year={year} businessUnit={businessUnit} />
                    </Collapse>
                </Col>
                <Col sm="6">
                    <Collapse isOpen={selectHamper}>
                        <BoxSelection year={year} businessUnit={businessUnit} />
                    </Collapse>
                </Col>
            </Row>}
        </Container>
    )
}